import React from 'react'
import {Form, Select} from 'antd';
import language from "../../../data/language.yaml";
import {MyTripContext} from "../../context/MyTripContext";
import Link from "../Link";

class SelectLocationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            locationId: null
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            locationId: `location_${this.props.locationId}`
        })
    }

    onChange = (locationId) => {
        this.setState({
            locationId: locationId
        })
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        let lang = language.member;
        let current = this.props.translations.current;

        let locationPath = (this.props.locale === 'en') ? '/' : '/cy/';
        locationPath += '?location=';
        if (this.state.locationId !== null) {
            locationPath += this.state.locationId.replace('location_', '');
        } else if (this.props.locations.length === 1) {
            locationPath += this.props.locations[0].wordpress_id;
        }
        locationPath += '#map'

        return (
            <Form>
                {this.props.locations.length > 1 ?
                    <Form.Item>
                        {getFieldDecorator('locationId', {
                            placeHolder: 'Please select one of our locations',
                            initialValue: this.props.locationId === null ? '' : `location_${this.props.locationId}`,
                        })(
                            <Select placeholder="" onChange={this.onChange} dropdownAlign={{ offset:[0,0] }}>
                                {this.props.locations.map((location, index) => (
                                    <Select.Option key={location.wordpress_id} value={`location_${location.wordpress_id}`}>{index+1}: {location.town}</Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                : ''}
                {this.state.locationId !== null || this.props.locations.length === 1 ?
                    <MyTripContext.Consumer>
                        {({ addLocation }) => (
                            <>
                            <Link className="btn btn-primary" onClick={() => {addLocation(this.state.locationId.replace('location_', ''))}}>
                                <span>{lang.addToTrip[current]}</span>
                                <span className="icon add"></span>
                            </Link>

                            <Link className="btn btn-white" to={locationPath}>
                                <span>{lang.viewOnMap[current]}</span>
                                <span className="icon map"></span>
                            </Link>
                            </>
                        )}
                    </MyTripContext.Consumer>
                    :
                    ''
                }
            </Form>
        );
    }
}

export default Form.create()(SelectLocationForm);
