import React, {Component} from 'react'
import {graphql} from "gatsby";
import Wrapper from '../components/Wrapper';
import PageHeader from "../components/PageHeader";
import {Col, Row, Button} from "antd";
import Link from "../components/Link";
import DiscoverMore from "../components/DiscoverMore";
import SelectLocationForm from "../components/MyTrip/SelectLocationForm";
import './members.less';
import Banner from "../components/Banner";
import {trackBookingClick, trackWebsiteClick} from "../services/gtm";

import language from "../../data/language.yaml";

class MembersPost extends Component {

    mapStyle = [
        '&style=feature:landscape.natural|element:geometry.fill|0xf2f2f2',
        '&style=feature:road|element:geometry.fill|color:0xdbdbdb',
        '&style=feature:water|element:geometry.fill|color:0x3c525d',
        '&style=feature:poi|visibility:off',
    ];

    constructor(props){
        super(props);

        const member = props.data.wordpressWpMember;

        let parsedUrl = '';
        let locationId = null;
        if(typeof window !== `undefined`) {
            parsedUrl = new URL(window.location.href);
            locationId = parsedUrl.searchParams.get('location');
            if(locationId===null){
                locationId = member.locations[0].wordpress_id
            }
        }

        this.state = {
            more : false,
            locationId: locationId,
            loaded: false
        };

    }

    toggleMore = () => {
        this.setState({
            more : !this.state.more,
        });
    };

    getGoogleMapImageURL = (color) => {
        // https://developers.google.com/maps/documentation/maps-static/dev-guide#introduction
        let markerColour = '';

        const member = this.props.data.wordpressWpMember;

        switch(color){
            case 'orange':
            default:
                markerColour = '0xEA5B1B';
                break;
            case 'teal':
                markerColour = '0x69C0AC';
                break;
            case 'gray':
                markerColour = '0xACADB4';
                break;
            case 'yellow':
                markerColour = '0xFBBA00';
                break;
        }

        let params = member.locations.map((location, x) => {
            return `&markers=color:${markerColour}|label:${x+1}|${location.latitude},${location.longitude}`;
        });

        let styles = this.mapStyle.join('');
        params = params.join('');
        return `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=roadmap${params}${styles}&key=${process.env.GATSBY_GOOGLE_MAP_API_KEY}`;
    };

    render() {

        const member = this.props.data.wordpressWpMember;

        let amenities = {};
        this.props.data.allWordpressWpAmenity.nodes.forEach(amenity => {
            amenities[amenity.wordpress_id] = amenity;
        })
        let passImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.snowdonia_pass_discount_image;

        let locationTypes = {};
        this.props.data.allWordpressWpLocationType.nodes.forEach(locationType => {
            locationTypes[locationType.wordpress_id] = locationType;
        });

        let current = member.translations.current;
        let lang = language.member;

        let hours = Math.floor(member.acf.average_visit_duration / 60);
        let minutes = member.acf.average_visit_duration % 60;
        let averageDuration = '';

        if(hours > 0 || minutes > 0) {
            if (hours > 0) {
                averageDuration = hours + ' ' + lang.averageDuration.hours[current] + (hours > 1 ? 's ' : ' ');
            }
            if (minutes > 0) {
                averageDuration += minutes + ' ' + lang.averageDuration.minutes[current] + (minutes > 1 ? 's' : '');
            }
        }

        const acfFields = member.acf;
        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = member.acf.name;
        }
        const slides = [];
        if(acfFields.banner_images) {
            acfFields.banner_images.forEach(banner => {
                if(banner.localFile){
                    slides.push(banner.localFile.childImageSharp.fluid);
                }
            });
        }

        let colour = "orange";
        if (member.locations) {
            colour = locationTypes[member.locations[0].type].acf.listing_colour;
        }

        let mapSourceImage = this.getGoogleMapImageURL(colour);

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                locale={member.translations.current}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                translations={member.translations}
                path={this.props.path}
            >
                <PageHeader
                    title={member.acf.name}
                    videoID={member.acf.banner_video_id}
                    slides={slides}
                    headerColor={colour}
                    // options: primary, secondary, orange, teal, yellow
                    headerStyle="cutout"
                    // options: landing, cutout, cutout-small, full
                    back="true"
                    date=""
                />
                <div className="single-member">
                    <Row type="flex" gutter={70}>
                        <Col xs={{span: 24}} md={{span: 12}} lg={{span: 14}}>

                            <div className="location-info">
                                <div className="key-info">
                                {/* {member.acf.snowdonia_pass && (
                                    <a href="#pass-details" className="pass-link">{lang.passDiscount.available[current]}</a>
                                )} */}

                                    {averageDuration !== '' ?
                                        <p className="loc-time">{lang.averageDuration[current]} {averageDuration}</p>
                                        : '' }
                                </div>

                                {member.acf.amenities && member.acf.amenities.length > 0 && (
                                    <div className="amenities-info">
                                        <h3>{lang.amenities[current]}</h3>
                                        <ul className="amenities-list">
                                            {member.acf.amenities.map(amenityID => (
                                                <li key={amenityID}><img src={amenities[amenityID].acf.amenity_icon.localFile.childImageSharp.original.src}
                                                                         alt=""/><span>{amenities[amenityID].title}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <div className="more-wrap" data-more={this.state.more}>
                                    <div className="description" dangerouslySetInnerHTML={{__html: member.acf.description}} />
                                </div>
                            </div>

                            <Button data-more={this.state.more} className={"read-more " + current} onClick={this.toggleMore}><span className="arrow" /></Button>

                            <div className="location-actions">

                                <Link className="btn btn-orange" to={member.acf.booking_url} onClick={() => trackBookingClick(member.acf.booking_url)}>
                                    <span>{lang.bookOnline[current]}</span>
                                    <span className="icon arrow"></span>
                                </Link>
                                <Link className="btn btn-white" to={member.acf.website_url} onClick={() => trackWebsiteClick(member.acf.website_url)}>
                                    <span>{lang.visitWebsite[current]}</span>
                                    <span className="icon arrow"></span>
                                </Link>
                            </div>
                        </Col>

                        <Col xs={{span: 24}} md={{span: 12}} lg={{span: 10}} className="member-locations-map">
                            <h3>Our locations</h3>
                            <img src={mapSourceImage} alt="locations on map"/>

                            <div className="member-locations">
                                <SelectLocationForm
                                    locations={member.locations}
                                    locationId={this.state.locationId}
                                    translations={member.translations}
                                    mapSource={mapSourceImage}
                                    locale={current}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* {member.acf.snowdonia_pass && (
                    <Banner
                        id="pass-details"
                        bgColor = "secondary"
                        image = {passImage}
                        headerColor = "yellow"
                        header = {member.acf.snowdonia_pass_discount_title}
                        paraColor = "white"
                        para = {member.acf.snowdonia_pass_discount_details}
                        btnLink = {lang.passDiscount.buttonLink[current]}
                        btnText = {lang.passDiscount.buttonText[current]}
                        btnColor = "yellow"
                    />
                )} */}

                <DiscoverMore member={member} locationId={this.state.locationId} locale={member.translations.current}/>
            </Wrapper>
        )
    }
}

export const memberQuery = graphql`
    query QueryMemberPage($id: String!) {
        wordpressWpMember(id: { eq: $id }) {
            id
            wordpress_id
            title
            slug
            acf {
                meta_title
                meta_description
                og_title
                og_description
                og_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
                twitter_title
                twitter_card_type
                twitter_description
                twitter_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
                
                amenities
                average_visit_duration
                booking_url
                description
                membership_level
                name
                snippet
                snowdonia_pass_discount_title
                snowdonia_pass_discount_details
                snowdonia_pass
                website_url
                banner_images{
                  localFile {
                      childImageSharp {
                          original {
                              width
                              height
                              src
                          }
                          fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                            src
                            srcSet
                            aspectRatio
                            sizes
                            base64
                          }
                      }
                  }
                }
                banner_video_id
            }
            translations {
                en
                cy
                current
            }
            locations {
                address_1
                address_2
                town
                county
                postcode
                latitude
                longitude
                name
                wordpress_id
                type
            }                 
        },
        allWordpressWpAmenity {
            nodes {
                title
                wordpress_id
                acf {
                    amenity_icon {
                        localFile {
                            childImageSharp {
                                original {
                                    width
                                    height
                                    src
                                }
                            }
                        }
                    }
                }
            }
        },
        allWordpressWpLocationType {
            nodes {
                acf {
                    listing_colour
                }
                wordpress_id
            }
        },
        allWordpressAcfOptions {
            edges {
                node {
                    id
                    options {
                        snowdonia_pass_discount_image {
                            localFile {
                                childImageSharp {
                                    original {
                                        width
                                        height
                                        src
                                    }
                                    fluid(quality: 80) {
                                        ... GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default MembersPost
